<template>
  <div class="dd-zy">
    <h3>优惠权益更超值</h3>
    <p>钉钉专业版，降低数字化成本</p>
    <div class="box">
      <img src="/static/img/zy-img-5.png" />
    </div>
    <div class="box">
      <h4>34款精选应用 专享向<span><i>9</i>折</span></h4>
      <img src="/static/img/zy-img-6.png" />
    </div>
  </div>

</template>
<script lang="ts">
export default {
  mounted() {

  }
}
</script>
<style lang="less" scoped>
.dd-zy {
  padding: 25px 15px;
  color: #383838;

  >h3 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  >p {
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 40px;
  }

  .box {
    padding: 10px;

    >h4 {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      >span{
        font-size: 18px;
        color: #E80B10;
      }
    }

    >img {
      display: block;
      max-width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
