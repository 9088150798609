<template>
  <div class="dd-zy">
    <h3>数字资产新升级</h3>
    <p>打造属于自己的数字化资产</p>
    <div class="box">
      <h4>组织数字化资产</h4>
      <img src="/static/img/zy_image_1.jpg" />
      <img src="/static/img/zy_image_2.jpg" />
    </div>
    <div class="box">
      <h4>业务数字化资产</h4>
      <img src="/static/img/zy_image_3.jpg" />
      <img src="/static/img/zy_image_4.jpg" />
    </div>
  </div>

</template>
<script lang="ts">
export default {
  mounted() {

  }
}
</script>
<style lang="less" scoped>
.dd-zy {
  padding: 25px 15px;
  color: #383838;

  >h3 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  >p {
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 40px;
  }

  .box {
    padding: 10px;

    >h4 {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }

    >img {
      display: block;
      max-width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
