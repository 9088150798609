<template>
  <div class="dd-cases-box">
    <h3>客户案例 · CASES</h3>
    <ul class="case-list">
      <li v-for="(item, index) in list" :key="index">
        <h3>{{ item.title }}</h3>
        <img :src="item.img" />
        <p>{{ item.remark }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        { img: '/static/img/zy-img-hc.jpg', title: '海创置业', remark: '依托钉钉，芜湖海创置业将日常管理、业务管理都迁移到了钉钉上，实现了数字化管理，科学决策。' },
        { img: '/static/img/zy-img-xy.jpg', title: '星逸文化', remark: '钉钉不仅改变了星逸员工的工作方式、思维方式和生活方式，更为集团构建了一套完整的神经网络，把集团的电商业务实现了线上化升级，接轨云和大数据。' },
        { img: '/static/img/zy-img-yg.jpg', title: '扬戈科技', remark: '扬戈科技联合钉钉积极探索“互联网+先进制造业”应用新模式，推动传统制造业向信息化、数字化的转型升级。' },
        
        ]
    }
  }
}
</script>
<style lang="less" scoped>
.dd-cases-box {
  padding: 25px 10px;
  background-color: #F6F6F8;

  >h3 {
    text-transform: uppercase;
    font-size: 18px;
    color: #383838;
    font-weight: bold;
    text-align: center;
    margin-bottom: 35px;
  }

  .case-list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    >li {
      width: 50%;
      padding: 15px 15px 20px;
      color: #383838;
      position: relative;
      margin: initial;

      >img {
        width: 100%;
        display: block;
        margin: 10px 0;
        z-index: 2;
        position: relative;
      }

      >h3 {
        font-size: 15px;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
        z-index: 2;
        position: relative;
      }

      >p {
        font-size: 13px;
        font-weight: 300;
        line-height: 1.5;
        z-index: 2;
        position: relative;
        text-align: justify;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        bottom: 3px;
        right: 3px;
        background: #FFFFFF;
        border: 1px solid #E8EAED;
      }
    }
  }
}
</style>