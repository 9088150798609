<template>
  <div class="dd-zy">
    <h3>尊享服务更优质</h3>
    <p>钉钉专业版，提升钉钉使用体验</p>
    <div class="box box1">
      <img src="/static/img/zy-img-7.png" />
      <img src="/static/img/zy-img-8.png" />
      <img src="/static/img/zy-img-9.png" />
    </div>
    <div class="box box2">
      <img src="/static/img/zy-img-13.png" />
      <img src="/static/img/zy-img-10.png" />
      <img src="/static/img/zy-img-11.png" />
    </div>
  </div>

</template>
<script lang="ts">
export default {
  mounted() {

  }
}
</script>
<style lang="less" scoped>
.dd-zy {
  padding: 25px 15px;
  color: #383838;

  >h3 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  >p {
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 40px;
  }

  .box {
    // box-shadow: 0 0 1px 0 rgba(0, 0, 0, .5);
    height: 150px;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, .2);
    overflow: hidden;
    position: relative;

    >img {
      position: absolute;
      height: 150px;
      top: 0;
    }

    &.box1 {
      >img {
        &:nth-child(1) {
          right: 0;
        }

        &:nth-child(2) {
          left: -50%;
        }

        &:nth-child(3) {
          height: 100px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &.box2 {
      background: url(/static/img/zy-img-12.png) repeat left top;
      background-size: auto 100px;

      >img {
        position: absolute;
        height: 150px;
        top: 0;

        &:nth-child(1) {
          height: 100px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:nth-child(2) {
          height: 70px;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:nth-child(3) {
          height: 60px;
          right: 55px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
