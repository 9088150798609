<template>
  <div class="dd-banner">
    <h3>钉钉专业版</h3>
    <p>助力企业打造属于自己的数字化资产</p>
    <button @click="openService">立即咨询</button>
  </div>
  <DDZYItem1 />
  <DDZYItem2 />
  <DDZYItem3 />
  <DDZYItem4 />
</template>
<script>
import DDZYItem1 from '../../components/dingtalk/DDZYItem1.vue';
import DDZYItem2 from '../../components/dingtalk/DDZYItem2.vue';
import DDZYItem3 from '../../components/dingtalk/DDZYItem3.vue';
import DDZYItem4 from '../../components/dingtalk/DDZYItem4.vue';
export default {
  components: {
    DDZYItem1,
    DDZYItem2,
    DDZYItem3,
    DDZYItem4
  }
}
</script>
<style lang="less" scoped>
.dd-banner {
  padding: 65px 25px;
  background: url(/static/img/dd-banner.jpg) no-repeat center;
  background-size: cover;
  color: #fff;

  >h3 {
    font-size: 20px;
    font-weight: bold;
  }

  >p {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.8;
    margin-top: 10px;
  }

  >button {
    height: 40px;
    background: #F23D49;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 30px;
    border: 0 none;
    width: 115px;
  }
}
</style>
